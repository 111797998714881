
import Vue from 'vue'

const locales = {keys(){return []}}
global['________'] = true;
delete global['________'];
global.__uniConfig = {"easycom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","PageWrapper":"@/components/Wrapper/PageWrapper.vue","MainWrapper":"@/components/Wrapper/MainWrapper.vue","ComponentWrapper":"@/components/Wrapper/ComponentWrapper.vue","qiun-data-charts":"@/components/qiun-data-charts/qiun-data-charts.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"globalStyle":{"navigationBarTextStyle":"black","navigationBarBackgroundColor":"#FFFFFF","navigationStyle":"custom","backgroundColor":"#F8F8F8"},"tabBar":{"color":"#7d7e80","selectedColor":"#1989fa","backgroundColor":"#FFFFFF","borderStyle":"black","list":[{"pagePath":"pages/index/index","iconPath":"static/tabbar/tab_home_default.png","selectedIconPath":"static/tabbar/tab_home_selected.png","text":"首页","redDot":false,"badge":""},{"pagePath":"pages/study/index","iconPath":"static/tabbar/tab_study_default.png","selectedIconPath":"static/tabbar/tab_study_selected.png","text":"已购课程","redDot":false,"badge":""},{"pagePath":"pages/member/index","iconPath":"static/tabbar/tab_personal_default.png","selectedIconPath":"static/tabbar/tab_personal_selected.png","text":"个人中心","redDot":false,"badge":""}]}};
global.__uniConfig.compilerVersion = '3.95';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '';
global.__uniConfig.appName = '';
global.__uniConfig.appVersion = '1.0.0';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/index/index"+'.vue')), 'pages-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-quality', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/index/quality"+'.vue')), 'pages-index-quality'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-favourable', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/index/favourable"+'.vue')), 'pages-index-favourable'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-study-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/study/index"+'.vue')), 'pages-study-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-search-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/search/index"+'.vue')), 'pages-search-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-course-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/course/index"+'.vue')), 'pages-course-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-course-author', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/course/author"+'.vue')), 'pages-course-author'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-course-package', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/course/package"+'.vue')), 'pages-course-package'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-course-alipay', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/course/alipay"+'.vue')), 'pages-course-alipay'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/index"+'.vue')), 'pages-member-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-auth', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/auth"+'.vue')), 'pages-member-auth'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-order-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/order/index"+'.vue')), 'pages-member-order-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-order-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/order/detail"+'.vue')), 'pages-member-order-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-order-evaluate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/order/evaluate"+'.vue')), 'pages-member-order-evaluate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-order-express', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/order/express"+'.vue')), 'pages-member-order-express'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-order-payment', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/order/payment"+'.vue')), 'pages-member-order-payment'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-setting-agreement', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/setting/agreement"+'.vue')), 'pages-member-setting-agreement'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-scan-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/scan/login"+'.vue')), 'pages-member-scan-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-member-login', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/member/login"+'.vue')), 'pages-member-login'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-problem-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/problem/index"+'.vue')), 'pages-problem-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-problem-contact', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/problem/contact"+'.vue')), 'pages-problem-contact'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-problem-clue', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/problem/clue"+'.vue')), 'pages-problem-clue'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-problem-subscribe', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/problem/subscribe"+'.vue')), 'pages-problem-subscribe'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-problem-agreement', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages/problem/agreement"+'.vue')), 'pages-problem-agreement'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-course-evaluate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/course/evaluate"+'.vue')), 'pages_sub-course-evaluate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-address', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/address"+'.vue')), 'pages_sub-member-address'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-address-edit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/address-edit"+'.vue')), 'pages_sub-member-address-edit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-collection', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/collection"+'.vue')), 'pages_sub-member-collection'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-follow', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/follow"+'.vue')), 'pages_sub-member-follow'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-cooperation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/cooperation"+'.vue')), 'pages_sub-member-cooperation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-qualification', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/qualification"+'.vue')), 'pages_sub-member-qualification'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-coupon', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/coupon"+'.vue')), 'pages_sub-member-coupon'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-privacy', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/privacy"+'.vue')), 'pages_sub-member-privacy'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-setting', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/setting"+'.vue')), 'pages_sub-member-setting'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-after-apply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/after/apply"+'.vue')), 'pages_sub-member-after-apply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-after-confirm', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/after/confirm"+'.vue')), 'pages_sub-member-after-confirm'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-after-history', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/after/history"+'.vue')), 'pages_sub-member-after-history'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-member-after-words', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/member/after/words"+'.vue')), 'pages_sub-member-after-words'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-creator-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/creator/index"+'.vue')), 'pages_sub-creator-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-creator-auth', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/creator/auth"+'.vue')), 'pages_sub-creator-auth'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-creator-package-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/creator/package/index"+'.vue')), 'pages_sub-creator-package-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-creator-package-action', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/creator/package/action"+'.vue')), 'pages_sub-creator-package-action'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-study-video', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/study/video"+'.vue')), 'pages_sub-study-video'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-study-voice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/study/voice"+'.vue')), 'pages_sub-study-voice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-study-article', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/study/article"+'.vue')), 'pages_sub-study-article'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-problem-certificate', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/problem/certificate"+'.vue')), 'pages_sub-problem-certificate'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-problem-purchase', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/problem/purchase"+'.vue')), 'pages_sub-problem-purchase'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-scope-oauth', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/scope/oauth"+'.vue')), 'pages_sub-scope-oauth'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-index-course-rank', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/index/course-rank"+'.vue')), 'pages_sub-index-course-rank'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_sub-index-author-rank', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_sub/index/author-rank"+'.vue')), 'pages_sub-index-author-rank'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/index"+'.vue')), 'pages_driving-driving-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-chapter', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/chapter"+'.vue')), 'pages_driving-driving-category-chapter'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-special', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/special"+'.vue')), 'pages_driving-driving-category-special'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-pratice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/pratice"+'.vue')), 'pages_driving-driving-category-pratice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-examination', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/examination"+'.vue')), 'pages_driving-driving-category-examination'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-history', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/history"+'.vue')), 'pages_driving-driving-category-history'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-collection', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/collection"+'.vue')), 'pages_driving-driving-category-collection'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-category-sync', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/category/sync"+'.vue')), 'pages_driving-driving-category-sync'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-change-model', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/change/model"+'.vue')), 'pages_driving-driving-change-model'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-change-position', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/change/position"+'.vue')), 'pages_driving-driving-change-position'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-console-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/console/index"+'.vue')), 'pages_driving-driving-console-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-console-category', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/console/category"+'.vue')), 'pages_driving-driving-console-category'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-console-examination', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/console/examination"+'.vue')), 'pages_driving-driving-console-examination'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-console-examination_end', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/console/examination_end"+'.vue')), 'pages_driving-driving-console-examination_end'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-console-other', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/console/other"+'.vue')), 'pages_driving-driving-console-other'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-real-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/real/index"+'.vue')), 'pages_driving-driving-real-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-real-console', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/real/console"+'.vue')), 'pages_driving-driving-real-console'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-search-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/search/index"+'.vue')), 'pages_driving-driving-search-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-search-text', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/search/text"+'.vue')), 'pages_driving-driving-search-text'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-search-voice', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/search/voice"+'.vue')), 'pages_driving-driving-search-voice'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-search-result', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/search/result"+'.vue')), 'pages_driving-driving-search-result'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-search-question', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/search/question"+'.vue')), 'pages_driving-driving-search-question'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-simulation-light', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/simulation/light"+'.vue')), 'pages_driving-driving-simulation-light'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-video-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/video/index"+'.vue')), 'pages_driving-driving-video-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-standard', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/standard"+'.vue')), 'pages_driving-driving-suggest-standard'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/order"+'.vue')), 'pages_driving-driving-suggest-order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/search"+'.vue')), 'pages_driving-driving-suggest-search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-judge', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/judge"+'.vue')), 'pages_driving-driving-suggest-judge'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-flow', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/flow"+'.vue')), 'pages_driving-driving-suggest-flow'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-explain', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/explain"+'.vue')), 'pages_driving-driving-suggest-explain'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_driving-driving-suggest-answer', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_driving/driving/suggest/answer"+'.vue')), 'pages_driving-driving-suggest-answer'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/index"+'.vue')), 'pages_volunteer-volunteer-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/index"+'.vue')), 'pages_volunteer-volunteer-college-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-major', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/major"+'.vue')), 'pages_volunteer-volunteer-college-major'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-major-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/major-detail"+'.vue')), 'pages_volunteer-volunteer-college-major-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-valuation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/valuation"+'.vue')), 'pages_volunteer-volunteer-college-valuation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-news', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/news"+'.vue')), 'pages_volunteer-volunteer-college-news'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-news-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/news-detail"+'.vue')), 'pages_volunteer-volunteer-college-news-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-college-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/college/detail"+'.vue')), 'pages_volunteer-volunteer-college-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-major-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/major/index"+'.vue')), 'pages_volunteer-volunteer-major-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-major-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/major/detail"+'.vue')), 'pages_volunteer-volunteer-major-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-partition-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/partition/index"+'.vue')), 'pages_volunteer-volunteer-partition-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-partition-supplement', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/partition/supplement"+'.vue')), 'pages_volunteer-volunteer-partition-supplement'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-wish-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/wish/index"+'.vue')), 'pages_volunteer-volunteer-wish-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-wish-me', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/wish/me"+'.vue')), 'pages_volunteer-volunteer-wish-me'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-wish-select', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/wish/select"+'.vue')), 'pages_volunteer-volunteer-wish-select'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages_volunteer-volunteer-wish-sheet', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("/Users/xin/Documents/developer/miniprogram/橙子有好课重构项目/src/pages_volunteer/volunteer/wish/sheet"+'.vue')), 'pages_volunteer-volunteer-wish-sheet'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"navigationBarTitleText":"首页","navigationStyle":"custom"})
      },
      [
        createElement('pages-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/index/quality',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"发现好课"})
      },
      [
        createElement('pages-index-quality', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-quality',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/quality',
  windowTop:0
}
},
{
path: '/pages/index/favourable',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠课程","navigationStyle":"custom"})
      },
      [
        createElement('pages-index-favourable', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-favourable',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/favourable',
  windowTop:0
}
},
{
path: '/pages/study/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"已购课程","navigationStyle":"custom"})
      },
      [
        createElement('pages-study-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-study-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/study/index',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/search/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"搜索课程"})
      },
      [
        createElement('pages-search-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-search-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/search/index',
  windowTop:0
}
},
{
path: '/pages/course/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程详情"})
      },
      [
        createElement('pages-course-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-course-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/course/index',
  windowTop:0
}
},
{
path: '/pages/course/author',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师主页"})
      },
      [
        createElement('pages-course-author', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-course-author',
  isNVue:false,maxWidth:0,
  pagePath:'pages/course/author',
  windowTop:0
}
},
{
path: '/pages/course/package',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"精品好课"})
      },
      [
        createElement('pages-course-package', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-course-package',
  isNVue:false,maxWidth:0,
  pagePath:'pages/course/package',
  windowTop:0
}
},
{
path: '/pages/course/alipay',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"去支付"})
      },
      [
        createElement('pages-course-alipay', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-course-alipay',
  isNVue:false,maxWidth:0,
  pagePath:'pages/course/alipay',
  windowTop:0
}
},
{
path: '/pages/member/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:2
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人中心","navigationStyle":"custom"})
      },
      [
        createElement('pages-member-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:3,
  name:'pages-member-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/index',
isQuit:true,
isTabBar:true,
tabBarIndex:2,
  windowTop:0
}
},
{
path: '/pages/member/auth',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"授权团队"})
      },
      [
        createElement('pages-member-auth', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-auth',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/auth',
  windowTop:0
}
},
{
path: '/pages/member/order/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的订单"})
      },
      [
        createElement('pages-member-order-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-order-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/order/index',
  windowTop:0
}
},
{
path: '/pages/member/order/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单详情"})
      },
      [
        createElement('pages-member-order-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-order-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/order/detail',
  windowTop:0
}
},
{
path: '/pages/member/order/evaluate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单评价"})
      },
      [
        createElement('pages-member-order-evaluate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-order-evaluate',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/order/evaluate',
  windowTop:0
}
},
{
path: '/pages/member/order/express',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"快递详情"})
      },
      [
        createElement('pages-member-order-express', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-order-express',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/order/express',
  windowTop:0
}
},
{
path: '/pages/member/order/payment',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"继续支付"})
      },
      [
        createElement('pages-member-order-payment', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-order-payment',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/order/payment',
  windowTop:0
}
},
{
path: '/pages/member/setting/agreement',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"购买协议"})
      },
      [
        createElement('pages-member-setting-agreement', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-setting-agreement',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/setting/agreement',
  windowTop:0
}
},
{
path: '/pages/member/scan/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"扫码登录"})
      },
      [
        createElement('pages-member-scan-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-scan-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/scan/login',
  windowTop:0
}
},
{
path: '/pages/member/login',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登陆"})
      },
      [
        createElement('pages-member-login', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-member-login',
  isNVue:false,maxWidth:0,
  pagePath:'pages/member/login',
  windowTop:0
}
},
{
path: '/pages/problem/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问题反馈"})
      },
      [
        createElement('pages-problem-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-problem-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/problem/index',
  windowTop:0
}
},
{
path: '/pages/problem/contact',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"在线客服"})
      },
      [
        createElement('pages-problem-contact', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-problem-contact',
  isNVue:false,maxWidth:0,
  pagePath:'pages/problem/contact',
  windowTop:0
}
},
{
path: '/pages/problem/clue',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"讲师申请"})
      },
      [
        createElement('pages-problem-clue', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-problem-clue',
  isNVue:false,maxWidth:0,
  pagePath:'pages/problem/clue',
  windowTop:0
}
},
{
path: '/pages/problem/subscribe',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"消息订阅"})
      },
      [
        createElement('pages-problem-subscribe', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-problem-subscribe',
  isNVue:false,maxWidth:0,
  pagePath:'pages/problem/subscribe',
  windowTop:0
}
},
{
path: '/pages/problem/agreement',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"入驻协议"})
      },
      [
        createElement('pages-problem-agreement', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-problem-agreement',
  isNVue:false,maxWidth:0,
  pagePath:'pages/problem/agreement',
  windowTop:0
}
},
{
path: '/pages_sub/course/evaluate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程评分"})
      },
      [
        createElement('pages_sub-course-evaluate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-course-evaluate',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/course/evaluate',
  windowTop:0
}
},
{
path: '/pages_sub/member/address',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"地址管理"})
      },
      [
        createElement('pages_sub-member-address', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-address',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/address',
  windowTop:0
}
},
{
path: '/pages_sub/member/address-edit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"编辑收货地址"})
      },
      [
        createElement('pages_sub-member-address-edit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-address-edit',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/address-edit',
  windowTop:0
}
},
{
path: '/pages_sub/member/collection',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的收藏"})
      },
      [
        createElement('pages_sub-member-collection', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-collection',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/collection',
  windowTop:0
}
},
{
path: '/pages_sub/member/follow',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的关注"})
      },
      [
        createElement('pages_sub-member-follow', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-follow',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/follow',
  windowTop:0
}
},
{
path: '/pages_sub/member/cooperation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商务合作"})
      },
      [
        createElement('pages_sub-member-cooperation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-cooperation',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/cooperation',
  windowTop:0
}
},
{
path: '/pages_sub/member/qualification',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"营业执照"})
      },
      [
        createElement('pages_sub-member-qualification', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-qualification',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/qualification',
  windowTop:0
}
},
{
path: '/pages_sub/member/coupon',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠券"})
      },
      [
        createElement('pages_sub-member-coupon', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-coupon',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/coupon',
  windowTop:0
}
},
{
path: '/pages_sub/member/privacy',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"用户协议"})
      },
      [
        createElement('pages_sub-member-privacy', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-privacy',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/privacy',
  windowTop:0
}
},
{
path: '/pages_sub/member/setting',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"设置"})
      },
      [
        createElement('pages_sub-member-setting', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-setting',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/setting',
  windowTop:0
}
},
{
path: '/pages_sub/member/after/apply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"申请售后"})
      },
      [
        createElement('pages_sub-member-after-apply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-after-apply',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/after/apply',
  windowTop:0
}
},
{
path: '/pages_sub/member/after/confirm',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"机构确认"})
      },
      [
        createElement('pages_sub-member-after-confirm', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-after-confirm',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/after/confirm',
  windowTop:0
}
},
{
path: '/pages_sub/member/after/history',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"协商历史"})
      },
      [
        createElement('pages_sub-member-after-history', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-after-history',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/after/history',
  windowTop:0
}
},
{
path: '/pages_sub/member/after/words',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"留言"})
      },
      [
        createElement('pages_sub-member-after-words', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-member-after-words',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/member/after/words',
  windowTop:0
}
},
{
path: '/pages_sub/creator/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"达人服务平台","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-creator-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-creator-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/creator/index',
  windowTop:0
}
},
{
path: '/pages_sub/creator/auth',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的挂载权限","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-creator-auth', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-creator-auth',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/creator/auth',
  windowTop:0
}
},
{
path: '/pages_sub/creator/package/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"配置货架页","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-creator-package-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-creator-package-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/creator/package/index',
  windowTop:0
}
},
{
path: '/pages_sub/creator/package/action',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"添加课程至货架","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-creator-package-action', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-creator-package-action',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/creator/package/action',
  windowTop:0
}
},
{
path: '/pages_sub/study/video',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"视频课程"})
      },
      [
        createElement('pages_sub-study-video', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-study-video',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/study/video',
  windowTop:0
}
},
{
path: '/pages_sub/study/voice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"音频课程","requiredBackgroundModes":["audio"]})
      },
      [
        createElement('pages_sub-study-voice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-study-voice',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/study/voice',
  windowTop:0
}
},
{
path: '/pages_sub/study/article',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"图文课程"})
      },
      [
        createElement('pages_sub-study-article', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-study-article',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/study/article',
  windowTop:0
}
},
{
path: '/pages_sub/problem/certificate',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商家证书"})
      },
      [
        createElement('pages_sub-problem-certificate', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-problem-certificate',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/problem/certificate',
  windowTop:0
}
},
{
path: '/pages_sub/problem/purchase',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"购买协议"})
      },
      [
        createElement('pages_sub-problem-purchase', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-problem-purchase',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/problem/purchase',
  windowTop:0
}
},
{
path: '/pages_sub/scope/oauth',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"账号授权"})
      },
      [
        createElement('pages_sub-scope-oauth', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-scope-oauth',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/scope/oauth',
  windowTop:0
}
},
{
path: '/pages_sub/index/course-rank',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"课程排行","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-index-course-rank', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-index-course-rank',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/index/course-rank',
  windowTop:0
}
},
{
path: '/pages_sub/index/author-rank',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"达人排行","navigationStyle":"custom"})
      },
      [
        createElement('pages_sub-index-author-rank', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_sub-index-author-rank',
  isNVue:false,maxWidth:0,
  pagePath:'pages_sub/index/author-rank',
  windowTop:0
}
},
{
path: '/pages_driving/driving/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"2024新规题库","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/index',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/chapter',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专项练习"})
      },
      [
        createElement('pages_driving-driving-category-chapter', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-chapter',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/chapter',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/special',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专项练习","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-special', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-special',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/special',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/pratice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"顺序练习","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-pratice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-pratice',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/pratice',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/examination',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟考试","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-examination', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-examination',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/examination',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/history',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"历史成绩","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-history', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-history',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/history',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/collection',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"错题·收藏","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-collection', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-collection',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/collection',
  windowTop:0
}
},
{
path: '/pages_driving/driving/category/sync',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"同步数据","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-category-sync', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-category-sync',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/category/sync',
  windowTop:0
}
},
{
path: '/pages_driving/driving/change/model',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"切换车型","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-change-model', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-change-model',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/change/model',
  windowTop:0
}
},
{
path: '/pages_driving/driving/change/position',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"切换定位"})
      },
      [
        createElement('pages_driving-driving-change-position', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-change-position',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/change/position',
  windowTop:0
}
},
{
path: '/pages_driving/driving/console/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"顺序练习"})
      },
      [
        createElement('pages_driving-driving-console-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-console-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/console/index',
  windowTop:0
}
},
{
path: '/pages_driving/driving/console/category',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分类练习"})
      },
      [
        createElement('pages_driving-driving-console-category', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-console-category',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/console/category',
  windowTop:0
}
},
{
path: '/pages_driving/driving/console/examination',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟考试"})
      },
      [
        createElement('pages_driving-driving-console-examination', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-console-examination',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/console/examination',
  windowTop:0
}
},
{
path: '/pages_driving/driving/console/examination_end',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟考试结束","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-console-examination_end', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-console-examination_end',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/console/examination_end',
  windowTop:0
}
},
{
path: '/pages_driving/driving/console/other',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"题目练习"})
      },
      [
        createElement('pages_driving-driving-console-other', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-console-other',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/console/other',
  windowTop:0
}
},
{
path: '/pages_driving/driving/real/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟真实考场"})
      },
      [
        createElement('pages_driving-driving-real-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-real-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/real/index',
  windowTop:0
}
},
{
path: '/pages_driving/driving/real/console',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟真实考场"})
      },
      [
        createElement('pages_driving-driving-real-console', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-real-console',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/real/console',
  windowTop:0
}
},
{
path: '/pages_driving/driving/search/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学法减分搜题"})
      },
      [
        createElement('pages_driving-driving-search-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-search-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/search/index',
  windowTop:0
}
},
{
path: '/pages_driving/driving/search/text',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"文字搜题"})
      },
      [
        createElement('pages_driving-driving-search-text', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-search-text',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/search/text',
  windowTop:0
}
},
{
path: '/pages_driving/driving/search/voice',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"语音搜题"})
      },
      [
        createElement('pages_driving-driving-search-voice', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-search-voice',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/search/voice',
  windowTop:0
}
},
{
path: '/pages_driving/driving/search/result',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"搜题结果"})
      },
      [
        createElement('pages_driving-driving-search-result', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-search-result',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/search/result',
  windowTop:0
}
},
{
path: '/pages_driving/driving/search/question',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"常见问题"})
      },
      [
        createElement('pages_driving-driving-search-question', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-search-question',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/search/question',
  windowTop:0
}
},
{
path: '/pages_driving/driving/simulation/light',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"灯光模拟","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages_driving-driving-simulation-light', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-simulation-light',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/simulation/light',
  windowTop:0
}
},
{
path: '/pages_driving/driving/video/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试视频"})
      },
      [
        createElement('pages_driving-driving-video-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-video-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/video/index',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/standard',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试标准","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-standard', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-standard',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/standard',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试预约","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-order',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/order',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"成绩查询","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-search',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/search',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/judge',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"评判标准","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-judge', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-judge',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/judge',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/flow',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"考试流程","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-flow', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-flow',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/flow',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/explain',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"项目讲解","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-explain', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-explain',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/explain',
  windowTop:0
}
},
{
path: '/pages_driving/driving/suggest/answer',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问题对策","navigationStyle":"custom"})
      },
      [
        createElement('pages_driving-driving-suggest-answer', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_driving-driving-suggest-answer',
  isNVue:false,maxWidth:0,
  pagePath:'pages_driving/driving/suggest/answer',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"志愿选报","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/index',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"查询大学","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-college-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/index',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/major',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"开设专业"})
      },
      [
        createElement('pages_volunteer-volunteer-college-major', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-major',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/major',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/major-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专业详情"})
      },
      [
        createElement('pages_volunteer-volunteer-college-major-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-major-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/major-detail',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/valuation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"学科评估"})
      },
      [
        createElement('pages_volunteer-volunteer-college-valuation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-valuation',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/valuation',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/news',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"招生信息"})
      },
      [
        createElement('pages_volunteer-volunteer-college-news', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-news',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/news',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/news-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"招生信息详情"})
      },
      [
        createElement('pages_volunteer-volunteer-college-news-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-news-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/news-detail',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/college/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"大学详情","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-college-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-college-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/college/detail',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/major/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"查询专业","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-major-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-major-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/major/index',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/major/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"专业详情"})
      },
      [
        createElement('pages_volunteer-volunteer-major-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-major-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/major/detail',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/partition/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"位次查询"})
      },
      [
        createElement('pages_volunteer-volunteer-partition-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-partition-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/partition/index',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/partition/supplement',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"完善信息"})
      },
      [
        createElement('pages_volunteer-volunteer-partition-supplement', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-partition-supplement',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/partition/supplement',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/wish/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"模拟志愿填报","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-wish-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-wish-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/wish/index',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/wish/me',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的志愿"})
      },
      [
        createElement('pages_volunteer-volunteer-wish-me', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-wish-me',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/wish/me',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/wish/select',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"志愿选择","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-wish-select', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-wish-select',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/wish/select',
  windowTop:0
}
},
{
path: '/pages_volunteer/volunteer/wish/sheet',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"当前志愿表","navigationStyle":"custom"})
      },
      [
        createElement('pages_volunteer-volunteer-wish-sheet', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages_volunteer-volunteer-wish-sheet',
  isNVue:false,maxWidth:0,
  pagePath:'pages_volunteer/volunteer/wish/sheet',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
