// 引入配置
import config from '@/common/config'
import store from '@/store/index.js'
import { usePlatform } from '@/hooks/useSystemInfo.js'
import { initializeUser } from '@/hooks/useUserInfo.js'
import { reportRequest } from '@/util/report.js'

//请求队列
let isRefreshing = false
let requests = []

// 初始化请求配置
uni.$u.http.setConfig((defaultConfig) => {
	/* defaultConfig 为默认全局配置 */
	defaultConfig.baseURL = config.baseUrl /* 根域名 */
	defaultConfig.timeout = 30000 /* 超时时间 */
	defaultConfig.custom.errorMessageType = 'none'
	defaultConfig.custom.prefetch = false

	return defaultConfig
})

uni.$u.http.interceptors.request.use((defaultConfig) => {
	// 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
	defaultConfig.data = defaultConfig.data || {}
	// 请求携带参数
	const { apiPlatform } = usePlatform()
	defaultConfig.params = Object.assign(defaultConfig.params, {
		channel: apiPlatform,
		third_app_id: config.thirdAppId || '',
	})
	if (defaultConfig.custom?.prefetch) {
		defaultConfig.usePrefetchCache = true
		defaultConfig.id = defaultConfig.custom.id || ''
	}
	// Authorization
	defaultConfig.header.Authorization = 'Bearer ' + store.state.$userInfo.access_token;
	// 上报
	reportRequest('request', defaultConfig)

	return defaultConfig
}, (defaultConfig) => {
	return Promise.reject(defaultConfig)
})

uni.$u.http.interceptors.response.use((response) => {
	/*  成功（statusCode === 200） */
	const data = response.data
	// 自定义参数
	const custom = response.config?.custom || {}
	// 判断
	if (data.code === 400) {
		if (custom.errorMessageType === 'toast') {
			uni.showToast({ icon: 'none', title: data.message })
		} else if (custom.errorMessageType === 'modal') {
			uni.showModal({ title: '错误', showCancel: false, content: data.message });
		}
	}
	if (data.code !== 200) {
		// 服务端返回的状态码不等于200，则reject()
		return Promise.reject(data)
	}

	return data.data || {}
}, async (response) => {
	/*  对响应错误做点什么 （statusCode !== 200） */
	if (response.errMsg.indexOf('timeout') !== -1) {
		uni.showToast({ icon: 'none', title: '请求超时，请检查网络设置' })
	}
	// 需要登陆
	if (response.statusCode === 401) {
		if (!isRefreshing) {
			try {
				//正在刷新
				isRefreshing = true
				//登陆
				await initializeUser()
				//执行请求队列
				requests.forEach(cb => cb())
				//重新执行
				return uni.$u.http.request(response.config)
			} finally {
				//结束刷新
				isRefreshing = false
				//清空请求队列
				requests = []
			}
		} else {
			return new Promise((resolve) => {
				requests.push(() => resolve(uni.$u.http.request(response.config)))
			})
		}
	}

	return Promise.reject(response?.data ? response.data : response)
})
